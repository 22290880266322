import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import CaseDetailsData from "../../types/CaseDetailsData";
import { DATA_FETCH_STATUS } from "../../types/DATA_FETCH_STATUS";
import { fetchCasesDetails } from "../../middleware";
import { CaseViewDetailsResponse } from "../../types/servertypes/servertypes";
import { getErrorData } from "../../../helper/DataTransform";

const initialState: CaseDetailsData = {
    dataStatus: DATA_FETCH_STATUS.NONE,
    error: undefined,
    caseDetails: undefined
}

const caseDetailsSlice = createSlice({
    name: 'caseDetails',
    initialState: initialState,
    reducers: {
        clearCaseDetails: (state) => {
        state.caseDetails = undefined
        state.dataStatus = DATA_FETCH_STATUS.NONE
    }},
    extraReducers: (builder) => {

        builder.addCase(fetchCasesDetails.pending, (state)=>{
            state.dataStatus = DATA_FETCH_STATUS.LOADING
        })

        builder.addCase(fetchCasesDetails.fulfilled,(state, action: PayloadAction<CaseViewDetailsResponse>) => {
            state.dataStatus = DATA_FETCH_STATUS.LOADED
            state.caseDetails = action.payload.getDetailsByCaseID
        })

        builder.addCase(fetchCasesDetails.rejected, (state, action) => {
            state.dataStatus = DATA_FETCH_STATUS.FAILED
            state.error = getErrorData(action.error.message)
        })
    }
})

export default caseDetailsSlice.reducer
export const { clearCaseDetails } = caseDetailsSlice.actions