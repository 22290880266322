import styled from "styled-components";
import { CommonButton, CommonContainer } from "../commonStyles/commonStyles";
import { FormStatus } from "@dnb/eufemia";

export const CustomerButton = styled(CommonButton)`

`;

export const CustomerContainer = styled(CommonContainer)`
  display: grid;

  padding: 24px;
  gap: 8px;
  background: #ffffff;
`;

export const CustomerHeader = styled.div`
  font-weight: bold ;
  font-family: "DNB";
`;

export const CustomerProperties = styled.div`
  font-family: "DNB";
  font-style: normal;

`;

export const PropertiesKey = styled.span`
  font-weight: bold;
`;

export const CustomerNotFound = styled(FormStatus)`
padding-top: 0.5rem;
justify-content: center;
`;
