import BusionessProperty from "../redux/types/BusionessProperty";
import CustomerCase from "../redux/types/CustomerCase";
import CustomerCases from "../redux/types/CustomerCases";
import CustomerData from "../redux/types/CustomerData";
import { DATA_FETCH_STATUS } from "../redux/types/DATA_FETCH_STATUS";
import { ErrorData } from "../redux/types/ErrorData";
import { ErrorType } from "../redux/types/ErrorType";
import Property from "../redux/types/Property";
import { CaseView, CaseViewResponse, CaseViews, Customer, ErrorResponse, PartyInfo } from "../redux/types/servertypes/servertypes";
import getPropertyValue from "./PropertyHelper";



export const transformCustomer = (response: Customer, fields: String[]): CustomerData => {

    var properties: Property[] = []

    // fields.forEach(key => {
    //     const value = getPropertyValue(response, key)
    //     properties.push({key, value})
    // })

    let personalnumber: Property = {
        key: "Personr",
        value: response.ssn
    }
    properties.push(personalnumber)

    let name: Property = {
        key: "Navn",
        value: response.givenName + response.familyName
    }
    properties.push(name)

    let lifecyclestatus: Property = {
        key: "Kundetatus",
        value: response.lifeCycleStatus
    }
    properties.push(lifecyclestatus)

    const transformedCustomer: CustomerData = { properties, dataStatus: DATA_FETCH_STATUS.LOADED }
    return transformedCustomer
}


export const transformCaseViewSummary = (response: CaseViews, fields: String[]): CustomerCases => {

    var customerCases: CustomerCase[] = []
    response.caseViews.forEach(data => {
        var properties: Property[] = []
        fields.forEach(key => {
            const caseSummary = data.caseSummary
            let updatedat: Property = {
                key: "Sist oppdatert",
                value: caseSummary.updatedAt.toString()
            }
            properties.push(updatedat)

            let product: Property = {
                key: "Produkt",
                value: caseSummary.productName
            }
            properties.push(product)

            let status: Property = {
                key: "Sakstatus",
                value: caseSummary.status
            }
            properties.push(status)

            let message: Property = {
                key: "Siste melding til kunde",
                value: caseSummary.messageToCustomer
            }
            properties.push(message)
        })

        customerCases.push({ caseId: data.caseSummary.caseId, properties, processes: [] })
    })

    return { cases: customerCases, dataStatus: DATA_FETCH_STATUS.LOADED }
}


export const getErrorData = (errorMsg?: string): ErrorData => {

    const firstIndex = errorMsg?.indexOf("{");

    const lastIndex = errorMsg?.lastIndexOf("}");

    const substring = errorMsg?.substring(firstIndex!!, lastIndex!! + 1);

    var errorResponse = JSON.parse(substring!!) as ErrorResponse;

    console.log(errorResponse)

    var errorType: ErrorType = ErrorType.NO_DATA_FOUND;
    if (errorResponse.response.errors[0].message == "Unauthorized") {
        errorType = ErrorType.UNAUTORIZED;
    }

    const errorData: ErrorData = {
        errorType,
        message: errorResponse.response.errors[0].message
    }

    return errorData
}



