import React from 'react'
import BusinessProcessAdditionalData from '../../redux/types/BusinesProcessAdditionalData'
import { AdditionalDetail } from '../../redux/types/servertypes/servertypes'
import { BusinessProcessContainer, BusinessProcessItemContainer, BusinessProcessItemTitle,BusinessProcessItemSubtitle } from './style'
import { convertCamelCaseToReadable } from '../../helper/Utils'

function BusinessProcess({title, subtitle}: BusinessProcessAdditionalData) {

  return (
    <BusinessProcessContainer>
      <BusinessProcessItemContainer>
        <BusinessProcessItemTitle>{convertCamelCaseToReadable(title)}</BusinessProcessItemTitle>
        <BusinessProcessItemSubtitle>{subtitle}</BusinessProcessItemSubtitle>
      </BusinessProcessItemContainer>
    </BusinessProcessContainer>
  )
}

export default BusinessProcess