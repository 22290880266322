import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCustomer, fetchParty } from "../../middleware";
import PartyData from "../../types/PartyData";
import { PartyInfo } from "../../types/servertypes/servertypes";
import { DATA_FETCH_STATUS } from "../../types/DATA_FETCH_STATUS";
import { getErrorData } from "../../../helper/DataTransform";


const initialState: PartyData = {
    dataStatus: DATA_FETCH_STATUS.NONE,
    error: undefined,
    partyIdfromInput: '',
    partyInfo: {
        partyId: "",
        partyStatus: "",
        partyType: "",
        customerNumber: ""
    }
}

const partySlice = createSlice({
    name: 'party',
    initialState: initialState,
    reducers: {
        setparty: (state, action) => {
            state.partyIdfromInput = action.payload
            state.dataStatus = DATA_FETCH_STATUS.NONE
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchParty.pending, (state) => {
            state.dataStatus = DATA_FETCH_STATUS.LOADING
        })

        builder.addCase(fetchParty.fulfilled, (state, action: PayloadAction<PartyInfo>) => {
            state.dataStatus = DATA_FETCH_STATUS.LOADED
            state.partyInfo = action.payload
        })

        builder.addCase(fetchParty.rejected, (state, action) => {
            state.dataStatus = DATA_FETCH_STATUS.FAILED
            console.log(action.error.message)
            state.error = getErrorData(action.error.message)
        })

    }
})

export default partySlice.reducer
export const { setparty } = partySlice.actions