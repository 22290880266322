import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchCaseSummary } from "../../redux/middleware";
import CustomerCase from "../../redux/types/CustomerCase";
import {
  CaseSummaryContainer,
  DateContainer,
  SummaryProperties,
  SummaryKey,
  CaseSummaryHeader,
  CaseSummaryTitle,
  CaseSummaryButton,
  CaseIdContainer,
} from "./style";
import ensure from "../../helper/Validate";
import { useLocation, useNavigate } from "react-router-dom";
import { ProgressIndicator } from "@dnb/eufemia"
import { convertCamelCaseToReadable } from "../../helper/Utils";

function CaseSummaryInfo({ caseDetails }: { caseDetails: CustomerCase }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const party = useAppSelector((state) => state.party)

  const caseStatus = (caseDetails: CustomerCase) => {
    const getStatus = ensure(
      "progress"
      // caseDetails.properties.find((x) => x.key === "status")?.value
    );
    return getStatus.toString().toLowerCase();
  };

  const caseId = (caseDetails: CustomerCase) => {
    const getId = ensure(
      caseDetails.properties.find((x) => x.key === "caseId")?.value
    );
    return getId.toString();
  };

  const caseDate = (caseDetails: CustomerCase) => {
    const getCreatedAt = ensure(
      caseDetails.properties.find((x) => x.key === "createdAt")?.value
    );
    let createdAt = new Date(getCreatedAt.toString());
    return createdAt.toLocaleString("no-NO", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  const formatUpdatedAtDate = (updatedAtDate: String) => {
    const d = new Date(updatedAtDate.toString())
    return d.toLocaleString(
      "no-NO",
      {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit"
      }
    )
  }

  const completedFields = ["productName", "status", "stage"];
  const excludeFields = ["createdAt", "caseId"];
  var status = "complete";

  const CaseSummaryContent = (caseDetails: CustomerCase) => {
    return (
      <>
        <CaseSummaryHeader>
          <CaseIdContainer>
            {t("caseId")}: {caseId(caseDetails)}
          </CaseIdContainer>
          <DateContainer>
            {caseDate(caseDetails)}
          </DateContainer>
        </CaseSummaryHeader>
        {caseStatus(caseDetails) !== status ? (
          <CaseSummaryTitle>
            {t("summary")}
          </CaseSummaryTitle>
        ) : null }
        {caseDetails.properties ? (
          caseStatus(caseDetails) === status ? (
            <div>
              {caseDetails.properties.map((property, index) => (
                <div key={index}>
                  {completedFields.includes(property.key.toString()) ? (
                    <SummaryProperties>
                      <SummaryKey> {convertCamelCaseToReadable(t(property.key.toString()))}: </SummaryKey>
                      {property.key.toString() === "updatedAt" ? "date" : "not date"}
                    </SummaryProperties>
                  ) : null }
                </div>
              ))}
            </div>
          ) : (
            <div>
              {caseDetails.properties.map((property, index) => (
                <div key={index}>
                  {excludeFields.includes(property.key.toString()) ? null : (
                    <SummaryProperties>
                      <SummaryKey> {convertCamelCaseToReadable(t(property.key.toString()))}: </SummaryKey>
                      {property.key.toString() === "updatedAt" ? formatUpdatedAtDate(property.value) : property.value}
                    </SummaryProperties>
                  )}
                </div>
              ))}
            </div>
          )
          ) : 
            null
          }
      </>
    )
  }

  return (
    <>
    {location.pathname === "/cases" ? 
      <CaseSummaryButton onClick={() => {
          navigate(caseDetails.caseId + "?partyId=" + party.partyInfo.partyId)
      }}>
        <CaseSummaryContainer $clickable>
          {CaseSummaryContent(caseDetails)}
        </CaseSummaryContainer>
      </CaseSummaryButton>
    :
      <CaseSummaryContainer>
        {CaseSummaryContent(caseDetails)}
      </CaseSummaryContainer>
    }
    </>
  );
}

export default CaseSummaryInfo;
