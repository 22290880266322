import styled from "styled-components";

export const CommonButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-align: left;
`

export const CommonContainer = styled.div<{$clickable?: boolean;}>`
  width: 400px;
  height: auto;

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  transition-duration: 0.4s;
  :hover {
    background: ${props => props.$clickable && "#d2f0e9"}
  }
`
