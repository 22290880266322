import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import "./locale/i18n"
import { BrowserRouter } from 'react-router-dom';
import '@dnb/eufemia/style'
import { AccountInfo, EventType, PublicClientApplication } from '@azure/msal-browser';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { getConfig } from './configuration/ConfigProps';
import App from './App';

// const pca = new PublicClientApplication({
//   auth: {
//     clientId: "eff3782a-2bad-4a62-afaf-1f9fe5b109e7",
//     authority: "https://login.microsoftonline.com/35ece5b7-b67d-4809-81b6-84f69d9786ed",
//     redirectUri: "http://localhost:3000/cases"
//   }
// })

// export const pca = new PublicClientApplication({
//   auth: {
//     clientId: "d4631623-b3e7-423d-b961-7610810f3528",
//     authority: "https://login.microsoftonline.com/4cbfea0a-b872-47f0-b51c-1c64953c3f0b",
//     redirectUri: "/cases"
//   }
// })

// export const pca = new PublicClientApplication({
//   auth: {
//     clientId: "603fcb64-2f8f-47f5-b742-85e44c5382fc",
//     authority: "https://login.microsoftonline.com/4cbfea0a-b872-47f0-b51c-1c64953c3f0b",
//     redirectUri: "/cases"
//   }
// })


export const configuration = getConfig(process.env.REACT_APP_ENVIRONMENT || "local")

console.log(process.env.REACT_APP_ENVIRONMENT)

export const pca = new PublicClientApplication({
  auth: {
    clientId: configuration.CLIENT_ID!,
    authority: configuration.AUTHORITY,
    redirectUri: "/cases"
  }
})

pca.addEventCallback(event => {
  if(event.eventType ===  EventType.LOGIN_SUCCESS){
    pca.setActiveAccount(event.payload as AccountInfo)
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//let persistor = persistStore(store)

// export const resetStore = async () => {
//   await persistor.purge();
//   await persistor.flush();
// };

root.render(
  <Provider store={store}>
    <BrowserRouter>
    {/* <PersistGate persistor={persistor}> */}
    {
      
      configuration.environment === "local"? <App maslInstance=""/> :  <App maslInstance={pca}/>
    }
     
      {/* <App /> */}
    {/* </PersistGate> */}
    </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
