import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCustomer } from "../../middleware";
import CustomerData from "../../types/CustomerData";
import { DATA_FETCH_STATUS } from "../../types/DATA_FETCH_STATUS";
import { ErrorResponse } from "../../types/servertypes/servertypes";
import { getErrorData } from "../../../helper/DataTransform";


const initialState: CustomerData = {
    dataStatus: DATA_FETCH_STATUS.NONE,
    error: undefined,
    properties: []
}

const customerSlice = createSlice({
    name: 'customer',
    initialState: initialState,
    reducers: {
        clearCustomer: (state) => {
            state.properties = []
            state.dataStatus = DATA_FETCH_STATUS.NONE
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchCustomer.pending, (state)=>{
            state.dataStatus = DATA_FETCH_STATUS.LOADING
        })
        
        builder.addCase(fetchCustomer.fulfilled, (state, action: PayloadAction<CustomerData>) => {
            state.dataStatus = DATA_FETCH_STATUS.LOADED
            state.properties = action.payload.properties
        })

        builder.addCase(fetchCustomer.rejected, (state, action) =>{
            state.dataStatus = DATA_FETCH_STATUS.FAILED
            state.error = getErrorData(action.error.message)
        })

    }
})

export default customerSlice.reducer
export const { clearCustomer } = customerSlice.actions