import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { useSearchParams } from 'react-router-dom';
import { fetchCaseSummary, fetchCustomer, fetchParty } from '../../../redux/middleware'
import CustomerInfo from '../../CustomerInfo/CustomerInfo'
import { CaseSummaryInfoContainer, CaseSummaryListContainer, CaseSummaryPageContainer, CaseSummaryPageCustomerContainer, CaseSummaryPageDataContainer, CaseSummaryPagePartySearchContainer, UnAuthorizedContainer } from './style'
import PartySearch from '../../PartySearch/PartySearch';
import CustomerData from '../../../redux/types/CustomerData';
import CaseSummaryInfo from '../../CaseSummaryInfo/CaseSummaryInfo';
import { ProgressIndicator } from '@dnb/eufemia'
import { DATA_FETCH_STATUS } from '../../../redux/types/DATA_FETCH_STATUS';
import CaseList from '../../CaseList/CaseList';
import { ErrorType } from '../../../redux/types/ErrorType';

function CaseSummaryPage() {

  const party = useAppSelector((state) => state.party)
  const customer = useAppSelector((state) => state.customer)

  return (
    <CaseSummaryPageContainer>
      <CaseSummaryPagePartySearchContainer>
        <PartySearch />
      </CaseSummaryPagePartySearchContainer>

      {
        party.dataStatus == DATA_FETCH_STATUS.LOADED &&
        <CaseSummaryPageDataContainer>
          <CaseSummaryPageCustomerContainer>
            {party.dataStatus == DATA_FETCH_STATUS.LOADED && <CustomerInfo/>}
          </CaseSummaryPageCustomerContainer>
          
          <CaseSummaryListContainer>
            {customer.dataStatus == DATA_FETCH_STATUS.LOADED && <CaseList caseId="" />}
          </CaseSummaryListContainer>

        </CaseSummaryPageDataContainer>
      }

    {
      customer.dataStatus == DATA_FETCH_STATUS.FAILED && customer.error?.errorType == ErrorType.UNAUTORIZED && <UnAuthorizedContainer text="You are not authorized to view the content" state="error"/>
    }


    </CaseSummaryPageContainer>
    
  )
}

export default CaseSummaryPage
