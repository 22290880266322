import React, { useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { BodyContainer, MainContainer } from './style'
import {Button} from '@dnb/eufemia'
import Header from '../../Header/Header'

function MainPage() {


  // const isAuthenticated = useIsAuthenticated()

  // const [accesstokenReceived, setAccesstokenReceived] = useState<boolean>(false)

  // const auth = useAppSelector((state) => state.auth)

  // const dispatch = useAppDispatch()

  // const msal  = useMsal();

  // useEffect(()=> {
  //   if(auth && auth.token !== ''){
  //     setAccesstokenReceived(true)
  //   }else{
  //     if(isAuthenticated){
  //       dispatch(fetchAzureADToken(msal))
  //     }
  //   }
  // },[auth])


  return (
    <MainContainer>
      {
        <>
          <Header/>
          <BodyContainer>
            <Outlet />
          </BodyContainer>
        </>
      }
        
    </MainContainer>

  //   <MainContainer>
  //   {
  //     <>
  //     <Header/>
  //     <BodyContainer>
  //       <Outlet />
  //     </BodyContainer>
  //   </>
  //   } 
  // </MainContainer>
  )
}

export default MainPage