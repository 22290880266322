import styled from "styled-components";
import { CommonButton, CommonContainer } from "../commonStyles/commonStyles";
import { FormStatus } from "@dnb/eufemia";


export const CaseListProgressBarContainer = styled(CommonContainer)`
display: grid;

padding: 24px;
gap: 8px;
background: #f4fbf9;
`;

export const CasesNotFound = styled(FormStatus)`
padding-top: 0.5rem;
justify-content: center;
`;