import { ConfigProps } from "./ConfigProps";

export class sit implements ConfigProps{
    API_BASE_URL: string;
    CLIENT_ID: string;
    AUTHORITY: string;
    environment: "sit";
    NAME_SPACE: string;

    constructor(){
        this.API_BASE_URL = "https://ncshbnx34becxgvq77updo66ee.appsync-api.eu-west-1.amazonaws.com/graphql"
        this.CLIENT_ID = "603fcb64-2f8f-47f5-b742-85e44c5382fc"
        this.AUTHORITY = "https://login.microsoftonline.com/4cbfea0a-b872-47f0-b51c-1c64953c3f0b"
        this.environment = "sit"
        this.NAME_SPACE = "retail-customer"
    }

}
