
import styled from "styled-components";

export const BusinessProcessContainer = styled.div`
display: flex;
flex-direction: column;
background: #EBEBEB;
margin-bottom: 0.5rem;
`;


export const BusinessProcessItemContainer = styled.div`
display: flex;
flex-direction: column;
background: #F8F8F8;
align-content: center;
padding: 1rem;
`;


export const BusinessProcessItemTitle = styled.p`
margin: 0;
padding: 3px;
font-weight: 500;
`;

export const BusinessProcessItemSubtitle = styled.p`
margin: 0;
padding: 3px;
`;