import { ConfigProps } from "./ConfigProps";

export class dev implements ConfigProps{
    API_BASE_URL: string;
    CLIENT_ID: string;
    AUTHORITY: string;
    environment: "dev";
    NAME_SPACE: string;

    constructor(){
        this.API_BASE_URL = "https://ncshbnx34becxgvq77updo66ee.appsync-api.eu-west-1.amazonaws.com/graphql"
        this.CLIENT_ID = "eff3782a-2bad-4a62-afaf-1f9fe5b109e7"
        this.AUTHORITY = "https://login.microsoftonline.com/35ece5b7-b67d-4809-81b6-84f69d9786ed"
        this.environment = "dev"
        this.NAME_SPACE = "retail-customer"
    }

}
