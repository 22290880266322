import { dev } from "./dev";
import { local } from "./local";
import { prod } from "./prod";
import { sit } from "./sit";
import { uat } from "./uat";

export interface ConfigProps{
    readonly environment: 'local' | 'dev' | 'sit' | 'uat' | 'prod';
    CLIENT_ID:string,
    AUTHORITY: string,
    API_BASE_URL: string,
    NAME_SPACE: string
  };

  export function getConfig(environment: 'local' | 'dev' | 'sit' | 'uat' | 'prod' | any): ConfigProps {
    switch (environment) {
      case 'local':
        return new local();
      case 'dev':
        return new dev();
      case 'sit':
        return new sit();
      case 'uat':
        return new uat();
      case 'prod':
        return new prod();
      default:
        throw new Error('Invalid type');
    }
  }