import React, { useEffect, useState } from 'react'
import {HeaderContainer, ImgHeader} from './style'
import { useMsal } from '@azure/msal-react'
import logo from '../../assets/cva.svg'; 
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
//import { resetStore } from '../..';

function Header() {
  const navigate = useNavigate()

  return (
    <HeaderContainer>
      <ImgHeader src={logo} onClick={() => navigate("/cases")}/>
    </HeaderContainer>
  )
}

export default Header