import { configureStore } from "@reduxjs/toolkit";

import caseReducer from "./features/cases/caseSlice";
import configReducer from "./features/config/configSlice"
import customerReducer from "./features/customer/customerSlice"
import partyReducer from "./features/party/partySlice"
import caseDetailsReducer from "./features/casedetails/caseDetailsSlice"

import storage from "redux-persist/lib/storage";

// import { persistReducer } from "redux-persist";

import { combineReducers } from "@reduxjs/toolkit";

// const persistConfig = {
//     key: "root",
//     version: 1,
//     storage
// }

const reducer = combineReducers({
    cases: caseReducer,
    caseDetails: caseDetailsReducer,
    configuration: configReducer,
    customer: customerReducer,
    party: partyReducer
})

// const persistedReducer = persistReducer(persistConfig, reducer)


const store = configureStore({
    reducer
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch