
import styled from "styled-components";
import { CommonContainer } from "../../commonStyles/commonStyles";
import { FormStatus } from "@dnb/eufemia";

export const CaseSummaryPageContainer = styled.div`
display: grid;
grid-template-rows: auto 1fr;
row-gap: 2rem;
justify-items: center;
align-items: center;
`;


export const CaseSummaryPagePartySearchContainer = styled.div`
grid-row-start: 1;
`;

export const CaseSummaryPageDataContainer = styled.div`
overflow-y: auto;
display: grid;
grid-row-start: 2;
grid-template-columns: repeat(2, 1fr);
column-gap: 2rem;
`;

export const CaseSummaryPageCustomerContainer = styled.div`
grid-column-start: 1;
`;

export const CaseSummaryListContainer = styled.div`
display: flex;
flex-direction: column;
gap: 2rem;
justify-content: space-around;
grid-column-start: 2;
margin-bottom: 2rem;
`;

export const CaseSummaryInfoContainer = styled(CommonContainer)`
display: grid;

padding: 24px;
gap: 8px;
background: #f4fbf9;
`;


export const UnAuthorizedContainer = styled(FormStatus)`
padding-top: 0.5rem;
justify-content: center;
`;
