import { createSlice } from "@reduxjs/toolkit";
import { ServerFields } from "../../types/servertypes/ServerFields";


const initialState: ServerFields = {
    customer: ["familyName","fullName","givenName","ssn"],
    party: ["partyId","customerNumber"],
    case: ["caseId","productName","createdAt","status","stage","updatedAt","messageToCustomer"],
    businessProcess: [ "businessProcessId","businessProcessName","status","statusCode"],
    products: []
}

const configSlice = createSlice({
    name: "configuration",
    initialState: initialState,
    reducers: {}
})

export default configSlice.reducer