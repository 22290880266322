import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchCaseSummary } from "../../redux/middleware";
import CustomerCase from "../../redux/types/CustomerCase";
import {
  CaseListProgressBarContainer,
  CasesNotFound,
} from "./style";
import ensure from "../../helper/Validate";
import { useLocation, useNavigate } from "react-router-dom";
import { ProgressIndicator } from "@dnb/eufemia"
import { DATA_FETCH_STATUS } from "../../redux/types/DATA_FETCH_STATUS";
import CaseSummaryInfo from "../CaseSummaryInfo/CaseSummaryInfo";



function CaseList({caseId }: { caseId: String }) {

  const party = useAppSelector((state) => state.party)

  const cases = useAppSelector((state)=> {
    if(caseId == ""){
      return state.cases
    }else{
      return {...state.cases,
        cases: state.cases.cases.filter(x => x.caseId == caseId)
      }
    }

  })

  const dispatch = useAppDispatch()

  useEffect(()=> {
    if(party.partyInfo.partyId !== '' && cases.cases.length <= 0){
      console.log("Calling case list")
      dispatch(fetchCaseSummary(party.partyInfo.partyId))
    }
  },[party])

  return (
    <>
      {cases.dataStatus === DATA_FETCH_STATUS.LOADED && cases.cases && cases.cases.length > 0 ? (
          cases.cases.map((casedata, index) => {
              return (<CaseSummaryInfo key={index} caseDetails={casedata} />)
          })
      ) : (
        cases.dataStatus === DATA_FETCH_STATUS.LOADED && <CasesNotFound text="Cases not found" state="error"/>
      )}

      {cases.dataStatus == DATA_FETCH_STATUS.LOADING &&
      <CaseListProgressBarContainer>
        <ProgressIndicator type='linear' size="small"/>
      </CaseListProgressBarContainer>
      
      }
      {cases.dataStatus == DATA_FETCH_STATUS.FAILED &&
      <CasesNotFound text="Cases not found" state="error"/>
      }
    </>
  )

}

export default CaseList;
