import React from 'react';
import logo from './logo.svg';
import './App.css';
import '@dnb/eufemia/style/core'

import { Navigate, Route, Routes } from 'react-router-dom';
import CaseSummaryPage from './components/pages/CaseSummaryPage/CaseSummaryPage';
import CaseDetailsPage from './components/pages/CaseDetailsPage/CaseDetailsPage';
import MainPage from './components/pages/MainPage/MainPage';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

interface MsalProps{
  maslInstance:any;
}


const App: React.FC<MsalProps> = ({ maslInstance }) => {
  // Render different components based on the variant

  if(maslInstance instanceof PublicClientApplication){
    return(
      <MsalProvider instance={maslInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <AuthenticatedTemplate>
                <Routes>
                  <Route path='/' element={<MainPage/>}>
                    <Route path='/' element={<Navigate to='/cases' replace/>}/>
                    <Route index path='cases' element={<CaseSummaryPage />}/>
                    <Route path='cases/:caseId' element={<CaseDetailsPage />}/>
                  </Route>
                </Routes>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                Not Authenticated
              </UnauthenticatedTemplate>
            </MsalAuthenticationTemplate>
          </MsalProvider>
  
    );
  }else {
    return (
    
      <Routes>
        <Route path='/' element={<MainPage/>}>
          <Route path='/' element={<Navigate to='/cases' replace/>}/>
          <Route index path='cases' element={<CaseSummaryPage />}/>
          <Route path='cases/:caseId' element={<CaseDetailsPage />}/>
        </Route>
      </Routes>
      
    );
  }

};

// export function App({maslInstance}:{maslInstance:PublicClientApplication}) {
//   return(
//     <MsalProvider instance={maslInstance}>
//           <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
//             <AuthenticatedTemplate>
//               <Routes>
//                 <Route path='/' element={<MainPage/>}>
//                   <Route path='/' element={<Navigate to='/cases' replace/>}/>
//                   <Route index path='cases' element={<CaseSummaryPage />}/>
//                   <Route path='cases/:caseId' element={<CaseDetailsPage />}/>
//                 </Route>
//               </Routes>
//             </AuthenticatedTemplate>
//             <UnauthenticatedTemplate>
//               Not Authenticated
//             </UnauthenticatedTemplate>
//           </MsalAuthenticationTemplate>
//         </MsalProvider>

//   );
// }


// export function App() {

//   return (
    
//     <Routes>
//       <Route path='/' element={<MainPage/>}>
//         <Route path='/' element={<Navigate to='/cases' replace/>}/>
//         <Route index path='cases' element={<CaseSummaryPage />}/>
//         <Route path='cases/:caseId' element={<CaseDetailsPage />}/>
//       </Route>
//     </Routes>
    
//   );
// }

export default App;
