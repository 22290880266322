import styled from "styled-components";
import {FormStatus, Input} from '@dnb/eufemia'


export const PartySearchInput = styled(Input)`

`;


export const PartySearchContainer = styled.div`
display: grid;
height: 130px;
width: 330px;
background-color: #f8f8f8;
grid-row-start: 1;
justify-content: center;
align-content: center;
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
border-radius: 4px;
`;


export const PartyNotFound = styled(FormStatus)`
padding-top: 0.5rem;
justify-content: center;
text-align: center;
max-width: 330px;
`;

