import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEnglish from "./en/translation.json"
import translationNorwegian from "./no/translation.json"

const resources = {
    en: {
        translation: translationEnglish,
    },
    no: {
        translation: translationNorwegian,
    }
}

i18next
.use(initReactI18next)
.use(LanguageDetector)
.init({
  resources,
  lng:"no", 
});

export default i18next;
