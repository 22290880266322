import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { fetchCaseSummary, fetchCasesDetails, fetchCustomer, fetchParty } from '../../../redux/middleware'
import CustomerInfo from '../../CustomerInfo/CustomerInfo'
import { BackButton, CaseDetailsPageContainer, CaseDetailsPageLeftContainer, CaseDetailsPageRightContainer, CaseSummaryInfoContainer, SkeletonButton } from './style'
import {Drawer, P, Button, ProgressIndicator, Skeleton, Textarea} from '@dnb/eufemia'
import { title } from 'process'
import CustomerCase from '../../../redux/types/CustomerCase'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AdditionalDetail, CaseViewDetails } from '../../../redux/types/servertypes/servertypes'
import BusinessProcess from '../../BusinessProcess/BusinessProcess'
import CaseSummaryInfo from '../../CaseSummaryInfo/CaseSummaryInfo'
import { Process } from '../../../redux/types/servertypes/servertypes'
import ProcessButtonComponent from '../../ProcessButton/ProcessButtonComponent'
import { useTranslation } from 'react-i18next'
import { DATA_FETCH_STATUS } from '../../../redux/types/DATA_FETCH_STATUS'
import CaseList from '../../CaseList/CaseList'
import { clearCustomer } from '../../../redux/features/customer/customerSlice'
import { clearCases } from '../../../redux/features/cases/caseSlice'
import { setparty } from '../../../redux/features/party/partySlice'
import { convertCamelCaseToReadable } from '../../../helper/Utils'
import { clearCaseDetails } from '../../../redux/features/casedetails/caseDetailsSlice'
import { CasesNotFound } from '../../CaseList/style'


function CaseDetailsPage() {

  const { t } = useTranslation()

  const {caseId} = useParams()

  const {party, customer, cases} = useAppSelector((state) => state)

  const [searchParams] = useSearchParams();
  const partyIdFromQueryString = searchParams?.get("partyId")!!;

  // const casedata: CustomerCase = useAppSelector((state) => state.cases.cases.find(x=> x.caseId === caseId))!!!

  const caseDetails = useAppSelector((state) => state.caseDetails)

  const dispatch = useAppDispatch()

  const [isOpen, setIsOpen] = useState(false)

  const [businessProcesses, setBusinessProcesses] = useState<AdditionalDetail[]>()

  const [drawerTitle, setDrawerTitle] = useState<string>()

  const navigate = useNavigate()

  // useEffect(()=>{
  //   // dispatch(fetchParty(party.partyIdfromInput))
  //   // dispatch(fetchCaseSummary(party.partyInfo.partyId))
  //   // dispatch(fetchCasesDetails(caseId!!!))
  // },[])

  useEffect(() => {
    if(party.partyIdfromInput == "" && partyIdFromQueryString != ""){
      dispatch(clearCustomer())
      dispatch(clearCases())
      dispatch(clearCaseDetails())
      dispatch(fetchParty(partyIdFromQueryString))
      dispatch(setparty(partyIdFromQueryString))
    }
  }, [party])


  useEffect(() => {
    if(party.dataStatus == DATA_FETCH_STATUS.LOADED && customer.dataStatus == DATA_FETCH_STATUS.LOADED && cases.dataStatus == DATA_FETCH_STATUS.LOADED){
      dispatch(clearCaseDetails())
      dispatch(fetchCasesDetails(caseId!!))
    }
  }, [party, customer, cases])


  const BusinessProcesses = (businessProcesses: AdditionalDetail[]) => {
    const businessProcessesJSON = JSON.parse(businessProcesses.toString())
    const businessProcessesKeys = Object.keys(businessProcessesJSON)

    return (
      businessProcessesKeys.map((key, index) =>{

        if(businessProcessesJSON[key] instanceof Object){

          const innerProcessJson = businessProcessesJSON[key]
          const innerProcessKeys = Object.keys(innerProcessJson)

          return(
            <>
            <h4>{convertCamelCaseToReadable(key)}</h4>
            {

              innerProcessKeys.map((innerkey, innerindex) =>{
                return(
                  <BusinessProcess 
                key={innerindex} 
                title={innerkey} 
                subtitle={innerProcessJson[innerkey]} />
                 )
              })
            }
            
            </>
          )
          
         
        }else{
          
          return(<BusinessProcess 
            key={index} 
            title={key} 
            subtitle={businessProcessesJSON[key]} 
          />)
        }
      }
        )
    )
  }



  return (
    <CaseDetailsPageContainer>
      <CaseDetailsPageLeftContainer>
        {party.dataStatus == DATA_FETCH_STATUS.LOADED && <CustomerInfo/>}
        {party.dataStatus == DATA_FETCH_STATUS.LOADED && <CaseList caseId={caseId!!}/>}
        {party.dataStatus == DATA_FETCH_STATUS.LOADED && customer.dataStatus == DATA_FETCH_STATUS.LOADED &&
           <BackButton text={t("back")} icon_position="left" icon="chevron_left" onClick={()=> {
            navigate("/cases", {replace: true})
          }}/>
        }
       
      </CaseDetailsPageLeftContainer>
      <CaseDetailsPageRightContainer>

      {caseDetails.dataStatus == DATA_FETCH_STATUS.LOADED && caseDetails.caseDetails && caseDetails.caseDetails.processes && caseDetails.caseDetails.processes.length > 0 ? (
        caseDetails.caseDetails.processes.map((process, index) => {
          return(
            <ProcessButtonComponent 
              key={index} 
              process={process} 
              handleClick={() => {
                setIsOpen(true)
                setDrawerTitle(process.name)
                setBusinessProcesses(process.additionalDetails)
              }}
            />
          )
        })
      ): (
        caseDetails.dataStatus == DATA_FETCH_STATUS.LOADED && <CasesNotFound text="Case details not found" state="error"/>
      )
        }

      {caseDetails.dataStatus == DATA_FETCH_STATUS.LOADING &&
        <CaseSummaryInfoContainer>
          <ProgressIndicator type='linear' size='small'/>
        </CaseSummaryInfoContainer>
      }

      {caseDetails.dataStatus == DATA_FETCH_STATUS.FAILED &&
      <CasesNotFound text="Case details not found" state="error"/>
      }



      {/* {caseDetails ? caseDetails.processes.map((process, index) => {
          return(
            <ProcessButtonComponent 
              key={index} 
              process={process} 
              handleClick={() => {

                setIsOpen(true)
                setDrawerTitle(process.name)
                setBusinessProcesses(process.additionalDetails)
              }}
            />
          )
        }): 
        <CaseSummaryInfoContainer>
          <ProgressIndicator type='linear' size='small'/>
        </CaseSummaryInfoContainer>
      } */}

      <Drawer openState={isOpen} 
        omitTriggerButton
        title={convertCamelCaseToReadable(drawerTitle!!)}
        onClose={() => {
          setIsOpen(false)
        }}> 
        {businessProcesses && BusinessProcesses(businessProcesses)}        
      </Drawer>

      </CaseDetailsPageRightContainer>
    </CaseDetailsPageContainer>
  )
}

export default CaseDetailsPage
