import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchCustomer } from "../../redux/middleware";
import CustomerData from "../../redux/types/CustomerData";
import { CustomerButton, CustomerContainer, CustomerHeader, CustomerNotFound, CustomerProperties, PropertiesKey } from "./style"
import { ProgressIndicator } from "@dnb/eufemia"
import { useLocation, useNavigate } from "react-router-dom"
import { DATA_FETCH_STATUS } from "../../redux/types/DATA_FETCH_STATUS";
import { useSelector } from "react-redux";
import PartyData from "../../redux/types/PartyData";
import { convertCamelCaseToReadable } from "../../helper/Utils";


function CustomerInfo() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  
  const dispatch = useAppDispatch()

  const party = useAppSelector((state) => state.party)

  const customerData = useAppSelector((state)=> state.customer)

  useEffect(()=> {
    if(party.partyInfo.partyId !== '' && customerData.properties.length <= 0){
      console.log("Calling customer")
      dispatch(fetchCustomer(party.partyInfo.partyId))
    }
  },[party])

  const CustomerContent = () => {
    return (
      <>
      {
        customerData.dataStatus == DATA_FETCH_STATUS.LOADED && 
        <div>
          <CustomerHeader>{t('customerInformation')}</CustomerHeader>
            {customerData.properties.map((property, index) => (
              <CustomerProperties key={index}>
                <PropertiesKey> {convertCamelCaseToReadable(t(property.key.toString()))}:</PropertiesKey> {property.value}
              </CustomerProperties>
            ))}
          </div>
      }
      {
        customerData.dataStatus == DATA_FETCH_STATUS.LOADING &&
        <ProgressIndicator type="linear" size="small"/>
      }
      {
        customerData.dataStatus == DATA_FETCH_STATUS.FAILED &&
        <CustomerNotFound text="Customer data not found" state="error"/>
      }
      </>
    )
  }

  return (
    <>
      {location.pathname === "/cases" ? 
        <CustomerContainer>
          {CustomerContent()}
        </CustomerContainer>
      :
        <CustomerButton onClick={() => {
          navigate("/cases")
        }}>
          <CustomerContainer $clickable>
            {CustomerContent()}
          </CustomerContainer>
        </CustomerButton>
      }
    </>
  )
}

export default CustomerInfo
