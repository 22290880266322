import { Button } from "@dnb/eufemia";
import styled from "styled-components";

export const RejectedButton = styled(Button)`
background-color: #DC2A2A;
color: white;
:hover{
    color: #DC2A2A;
    background-color: white;
}
`;

export const ErrorButton = styled(Button)`
background-color: #FF5400;
color: white;
:hover{
    color: #FF5400;
    background-color: white;
}
`;


export const OpenButton = styled(Button)`
opacity: 0.3px
`;