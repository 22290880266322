import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchParty } from "../../redux/middleware";
import { PartyNotFound, PartySearchContainer, PartySearchInput } from "./style";
import { useLocation, useSearchParams } from "react-router-dom";
import { setparty } from "../../redux/features/party/partySlice";
import { useTranslation } from "react-i18next";
import { ProgressIndicator } from "@dnb/eufemia"
import { DATA_FETCH_STATUS } from "../../redux/types/DATA_FETCH_STATUS";
import { clearCustomer } from "../../redux/features/customer/customerSlice";
import { clearCases } from "../../redux/features/cases/caseSlice";
import { clearCaseDetails } from "../../redux/features/casedetails/caseDetailsSlice";
import { ErrorType } from "../../redux/types/ErrorType";


function PartySearch() {
  // const [searchParams] = useSearchParams();
  // const partyIdFromQueryString = searchParams?.get("partyId")!!;
  
  // const [partyId, setPartyId] = useState<string>(() => {
  //   console.log("state set 1")
  //   if (partyIdFromQueryString && partyIdFromQueryString !== "") {
  //     return partyIdFromQueryString;
  //   } else {
  //     return "";
  //   }
  // });

  const locaStorageKey = "partyId";

  const [partyId, setPartyId] = useState<string>();

  const dispatch = useAppDispatch();

  const party = useAppSelector((state) => state.party)

  // useEffect(() => {
  //   console.log("useEffect set 1")
  //   const localPartyId = localStorage.getItem(locaStorageKey)
  //   if(localPartyId != undefined && localPartyId != null){
  //     setPartyId(localPartyId)
  //   }
  // }, []);

  useEffect(() => {
    if(party.partyIdfromInput != undefined && party.partyIdfromInput != null){
      setPartyId(party.partyIdfromInput)
    }
  }, []);

  // useEffect(() => {
  //   console.log("useEffect set 2")
  //   setPartyId(partyIdFromQueryString)
  // }, [partyIdFromQueryString]);


  useEffect(()=>{
    if(partyId != undefined && partyId != "" && party.partyIdfromInput != partyId){
      dispatch(setparty(partyId))
    }
  },[partyId])


  useEffect(()=>{
    if(party.partyIdfromInput != undefined && party.partyIdfromInput != "" && party.dataStatus != DATA_FETCH_STATUS.LOADED){
      dispatch(clearCustomer())
      dispatch(clearCases())
      dispatch(clearCaseDetails())
      dispatch(fetchParty(party.partyIdfromInput))
    }
  },[party.partyIdfromInput])


  const { t } = useTranslation();

  return (
    <>
    <PartySearchContainer>
      <PartySearchInput
        label={t("searchCases")}
        placeholder={t("customerId").toString()}
        label_direction="vertical"
        type="search"
        value={partyId}
        on_submit={({ value }) => {
          setPartyId(value);
        }}
      />
      
    </PartySearchContainer>
    {
      party.dataStatus == DATA_FETCH_STATUS.LOADING && <ProgressIndicator type="linear" size="small"/>
    }

    {
      party.dataStatus == DATA_FETCH_STATUS.FAILED && party.error?.errorType == ErrorType.NO_DATA_FOUND? <PartyNotFound text="Party id not found" state="error"/> :
      party.dataStatus == DATA_FETCH_STATUS.FAILED && party.error?.errorType == ErrorType.UNAUTORIZED? <PartyNotFound text="You are not authorized to view the content" state="error"/> : ""
    } 
    
    </>
    
  );
}

export default PartySearch;
