import { ConfigProps } from "./ConfigProps";

export class local implements ConfigProps{
    API_BASE_URL: string;
    CLIENT_ID: string;
    AUTHORITY: string;
    environment: "local";
    NAME_SPACE: string;

    constructor(){
        this.API_BASE_URL = "https://ncshbnx34becxgvq77updo66ee.appsync-api.eu-west-1.amazonaws.com/graphql"
        this.CLIENT_ID = ""
        this.AUTHORITY = ""
        this.environment = "local"
        this.NAME_SPACE = "retail-customer"
    }

}
