import {createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchCasesDetails, fetchCaseSummary, fetchCustomer } from "../../middleware"
import CustomerCases from "../../types/CustomerCases"
import { CaseView, CaseViewDetailsResponse, CaseViewResponse, CaseViews, ErrorResponse } from "../../types/servertypes/servertypes"
import { DATA_FETCH_STATUS } from "../../types/DATA_FETCH_STATUS"
import { getErrorData } from "../../../helper/DataTransform"

const initialState: CustomerCases = {
    dataStatus: DATA_FETCH_STATUS.NONE,
    error: undefined,
    cases: []
}


const caseSlice = createSlice({
    name: 'cases',
    initialState: initialState,
    reducers: {
        clearCases: (state) => {
        state.cases = []
        state.dataStatus = DATA_FETCH_STATUS.NONE
    }},
    extraReducers: (builder) => {

        builder.addCase(fetchCaseSummary.pending, (state)=>{
            state.dataStatus = DATA_FETCH_STATUS.LOADING
        })

        builder.addCase(fetchCaseSummary.fulfilled, (state, action: PayloadAction<CustomerCases>) => {
            state.dataStatus = DATA_FETCH_STATUS.LOADED
            state.cases = []
            state.cases = action.payload.cases
        })

        builder.addCase(fetchCaseSummary.rejected, (state, action) => {
            state.dataStatus = DATA_FETCH_STATUS.FAILED
            state.error = getErrorData(action.error.message)
            state.cases = []
        })

    }
})

export default caseSlice.reducer
export const { clearCases } = caseSlice.actions