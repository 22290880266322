
import styled from "styled-components";
import {Button} from '@dnb/eufemia'


export const MainContainer = styled.div`
    display: grid;
    grid-template-rows: 80px 1fr;
`;



export const BodyContainer = styled.div`
    grid-row-start: 2;
    display: grid;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 4rem;

    &::-webkit-scrollbar {
    width: 8px;
  }
 
  &::-webkit-scrollbar-track {
    border-radius: 50px;
  }
 
  &::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 50px;
  }
 
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;